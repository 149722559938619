import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import React, { Suspense } from 'react';

const Tortuga = React.lazy(() => import('./Tortuga/TortugaApp'));
const Industrialna = React.lazy(() => import('./Industrialna/IndustrialnaApp'));
//const Projekt2 = React.lazy(() => import('./Projekt2'));

function App() {
  return (
    <Router>
      <div>
        <Suspense fallback={<div>Ładowanie...</div>}>
          <Routes>
            <Route path="/:projectId/*" element={<ProjectWrapper />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

function ProjectWrapper() {
  const { projectId } = useParams();

  switch (projectId) {
    case 'Tortuga':
      return <Tortuga />;
    case 'Industrialna':
      return <Industrialna />;
    default:
      return <div>Nie znaleziono projektu</div>;
  }
}

export default App;